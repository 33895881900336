/* --- Header text feature --- */
.call-out {
  @extend %padding-header;
  display: inline-block;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  text-align: center;
  color: $header-text-color;
  background: $header-background-color no-repeat;
  background-size: $feature-image-size;
}

* {
  box-sizing: border-box;
}

.leftcolumn {
  float: left;
  width: 70%;
  padding: 0 0 2% 2%;
}

.rightcolumn {
  float: left;
  width: 30%;
  padding-left: 20px;
  padding-right: 7%;
}

.postcard {
  padding: 3% 3% 3% 15%;
  @extend %padding-post;
  float: left;
  margin-bottom: 0;
  display: inline-block;
  background-size: $feature-image-size;
  border-bottom: 1px solid $border-color;
}

.post-img {
  vertical-align: middle;
  border-radius: 10px;
  overflow: hidden;

  @media (min-width: $break) {
    height: 250px;
  }

  //Smaller screen
  @media screen and (max-width: $break) {
    height: 150px;
  }

  img {
    width: 100%;
    padding: 0;
    vertical-align: middle;
  }

}

.post-img img {
  -moz-transition-timing-function: ease-out;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;

  -moz-transition: all 2s;
  -webkit-transition: all 2s;
  transition: all 2s;

  &:hover {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.excerpt {
    margin-top: 1em;
}

.sidebarcard {
  background-color: #f1f1f1;
  padding: 20px;
  margin-top: 20px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: $break) {
  .leftcolumn, .rightcolumn {
    width: 100%;
    padding:0;
  }

  .postcard {
    width: 100%;
    padding: 3%;
  }
}